

function CoursesExtSection() {
  return (
    <section id="courses" className="courses">
      <div className="container" data-aos="fade-up">

        <div className="row" data-aos="zoom-in" data-aos-delay="100">

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="course-item">
              <img src="assets/img/course-1.png" className="img-fluid" alt="..." />
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4>Python</h4>
                </div>

                <h3><a href="course-details.html">Python для начинающих</a></h3>
                <p>Python - общепризнанный язык первого обучения и выбран таковым за свою простоту и легкость вхождения. Вам будет легко научиться на нем программировать!</p>

                <div className="trainer d-flex justify-content-between align-items-center next-line">
                  <div className="trainer-profile d-flex align-items-center">
                    <span>Курс 1</span>
                  </div>
                  <div className="trainer-rank d-flex align-items-center">
                    &nbsp;8 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                  </div>
                </div>

                <div className="trainer d-flex justify-content-between align-items-center next-line">
                    <div className="trainer-profile d-flex align-items-center">
                      <span>Курс 2</span>
                    </div>
                    <div className="trainer-rank d-flex align-items-center">
                      &nbsp;8 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                    </div>
                </div>

                <div className="trainer d-flex justify-content-between align-items-center">
                    <div className="trainer-profile d-flex align-items-center">
                      <span>Курс 3</span>
                    </div>
                    <div className="trainer-rank d-flex align-items-center">
                      &nbsp;12 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                    </div>
                </div>

              </div>
            </div>
          </div> 

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className="course-item">
              <img src="assets/img/course-2.png" className="img-fluid" alt="..." />
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4>Javascript</h4>
                </div>

                <h3><a href="course-details.html">Javascript для начинающих</a></h3>
                <p>Запрограммировать броузер? Легко! Для этого достаточно изучить язык javascript. Как это сделать, узнаем на этом курсе </p>

                <div className="trainer d-flex justify-content-between align-items-center next-line">
                  <div className="trainer-profile d-flex align-items-center">
                    <span>Курс 1</span>
                  </div>
                  <div className="trainer-rank d-flex align-items-center">
                    &nbsp;8 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                  </div>
                </div>

                <div className="trainer d-flex justify-content-between align-items-center next-line">
                    <div className="trainer-profile d-flex align-items-center">
                      <span>Курс 2</span>
                    </div>
                    <div className="trainer-rank d-flex align-items-center">
                      &nbsp;8 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                    </div>
                </div>

                <div className="trainer d-flex justify-content-between align-items-center">
                    <div className="trainer-profile d-flex align-items-center">
                      <span>Курс 3</span>
                    </div>
                    <div className="trainer-rank d-flex align-items-center">
                      &nbsp;12 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                    </div>
                </div>

              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="course-item">
              <img src="assets/img/course-3.png" className="img-fluid" alt="..." />
              <div className="course-content">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4>C++</h4>
                </div>

                <h3><a href="course-details.html">C++ для начинающих</a></h3>
                <p>Считается, что C++ более сложный язык для изучения, но нет преград, если учишься играючи!</p>

                <div className="trainer d-flex justify-content-between align-items-center next-line">
                  <div className="trainer-profile d-flex align-items-center">
                    <span>Курс 1</span>
                  </div>
                  <div className="trainer-rank d-flex align-items-center">
                    &nbsp;10 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                  </div>
                </div>

                <div className="trainer d-flex justify-content-between align-items-center next-line">
                    <div className="trainer-profile d-flex align-items-center">
                      <span>Курс 2</span>
                    </div>
                    <div className="trainer-rank d-flex align-items-center">
                      &nbsp;10 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                    </div>
                </div>

                <div className="trainer d-flex justify-content-between align-items-center">
                    <div className="trainer-profile d-flex align-items-center">
                      <span>Курс 3</span>
                    </div>
                    <div className="trainer-rank d-flex align-items-center">
                      &nbsp;15 &nbsp;<i className="bx bx-book-open" title="занятий"></i>
                    </div>
                </div>
              </div>
            </div>
          </div> 

        </div>

      </div>
    </section>

  );
}

export default CoursesExtSection;
