

function PricingSection() {
  return (
    <section id="pricing" className="pricing">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Оплата</h2>
          <p>Стоимость занятий</p>
        </div>

        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="box">
              <h3>Одно занятие</h3>
              <h4><sup>$</sup>16</h4>
              <ul>
                <li>Оплата карточкой $16</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div className="box featured">
              <h3>5 занятий</h3>
              <h4><sup>$</sup>15,50</h4>
              <ul>
                <li>Оплата карточкой 5x$15.50 = $77.50</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
            <div className="box">
              <span className="advanced">Advanced</span>
              <h3>10 занятий</h3>
              <h4><sup>$</sup>15</h4>
              <ul>
                <li>Оплата карточкой 10*$15 = $150 </li>
              </ul>
            </div>
          </div>

        </div>

      </div>
    </section>

  );
}

export default PricingSection;
