import { NavLink } from 'react-router-dom';


function Unknown() {
  return (
    <section id="hero" className="d-flex justify-content-center align-items-center">
      <div className="container position-relative" data-aos="zoom-in" data-aos-delay="100">
        <h1>Error 404:<br/> Упс! Страница с таким адресом не найдена</h1>
          <h2>Возможно страница была переименована или удалена. <br/>Чтобы перейти на главную страницу кликните здесь:</h2>
            <a href="/" className="btn-get-started">Главная страница</a>
    </div>
  </section>
  );
}

export default Unknown;
