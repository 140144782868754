

function AboutSection() {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Курсы</h2>
          <p>Программы курсов</p>
        </div>

        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <img src="assets/img/game-1.png" className="img-fluid" alt="Snake" />
          </div>

          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h3>Курс 1: Линейное программирование</h3>
            <p className="font-italic">
              На этом курсе мы узнаем:</p>
            <ul>
              <li><i className="icofont-check-circled"></i> Как записывать программу и что такое среда разработки;</li>
              <li><i className="icofont-check-circled"></i> Что такое операторы и переменные;</li>
              <li><i className="icofont-check-circled"></i> Что такое циклы и какие они бывают разные;</li>
              <li><i className="icofont-check-circled"></i> Как программа ветвится и зачем;</li>
              <li><i className="icofont-check-circled"></i> Что такое функция и зачем она нужна;</li>
              <li><i className="icofont-check-circled"></i> Что такое событие и как его связать с обработчиком;</li>
              <li><i className="icofont-check-circled"></i> Как рисовать на экране и как стирать нарисованное;</li>
              <li><i className="icofont-check-circled"></i> Как добиться движения персонажа на экране;</li>
              <li><i className="icofont-check-circled"></i> Что такое массив и зачем его придумали;</li>
              <li><i className="icofont-check-circled"></i> И, наконец, как сделать всем известную игру <b>"змейка"</b>!</li>
            </ul>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 content">
            <h3>Курс 2: Введение в ООП</h3>
            <p className="font-italic">
              На этом курсе мы узнаем что такое объектно-ориентированное программирование (ООП), а также:</p>
            <ul>
              <li><i className="icofont-check-circled"></i> Что такое класс и зачем он нужен;</li>
              <li><i className="icofont-check-circled"></i> Что такое поля и методы класса;</li>
              <li><i className="icofont-check-circled"></i> Что такое конструктор класса;</li>
              <li><i className="icofont-check-circled"></i> Как порождать экземпляры класса;</li>
              <li><i className="icofont-check-circled"></i> Что такое видимость метода и почему метод может быть приватным;</li>
              <li><i className="icofont-check-circled"></i> Как взаимодействуют классы между собой;</li>
              <li><i className="icofont-check-circled"></i> И, в итоге, как написать с помощью ООП всем известную игру <b>"тетрис"</b>!</li>
              <li><i className="icofont-check-circled"></i> А еще узнаем, что такое callback.</li>
            </ul>
          </div>
          <div className="col-lg-6 order-2 order-lg-2" data-aos="fade-right" data-aos-delay="100">
            <img src="assets/img/game-2.png" className="img-fluid" alt="Tetris" />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <img src="assets/img/game-3.png" className="img-fluid" alt="PacMan" />
          </div>

          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h3>Курс 3: ООП в действии</h3>
            <p className="font-italic">
              На этом курсе мы узнаем:</p>
            <ul>
              <li><i className="icofont-check-circled"></i> Как сделать проект и зачем нужно разносить код по отдельным файлам;</li>
              <li><i className="icofont-check-circled"></i> Зачем классу предки;</li>
              <li><i className="icofont-check-circled"></i> Что наследуют потомки;</li>
              <li><i className="icofont-check-circled"></i> Что значит слово "полиморфизм", и такое ли оно страшное;</li>
              <li><i className="icofont-check-circled"></i> Как управлять сразу несколькимо экземплярами класса;</li>
              <li><i className="icofont-check-circled"></i> Как менять поведение объекта;</li>
              <li><i className="icofont-check-circled"></i> И как все это поможет написать всем известную игру <b>"PacMan"</b>!</li>
              <li><i className="icofont-check-circled"></i> А еще откроем тайну, как рандом читерит преследователям колобка;</li>
            </ul>
          </div>
        </div>

      </div>


    </section>
  );
}

export default AboutSection;
