import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import CoursesSection from '../components/CoursesSection';


function IndexPage() {
  return (
    <div className="App">
          <Header />
          <HeroSection />
          <main id="main" data-aos="fade-in">
            <a name="courses">&nbsp;</a>
            <CoursesSection />
          </main>
          <Footer />
     </div>
   );
}

export default IndexPage;
