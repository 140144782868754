import Header from '../components/Header';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';
import CoursesExtSection from '../components/CoursesExtSection';
import AboutSection from '../components/AboutSection';
import PricingSection from '../components/PricingSection';


function IndexPage() {
  return (
    <div className="App">
          <Header />
          <main id="main" data-aos="fade-in">
            <Breadcrumbs />
            <CoursesExtSection />
            <AboutSection />
            <PricingSection />
          </main>
          <Footer />
     </div>
   );
}

export default IndexPage;
