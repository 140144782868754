import { NavLink } from 'react-router-dom';

function CoursesSection() {
  return (
    <section id="why-us" className="why-us">
      <div className="container" data-aos="fade-up">

        <div className="row">
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="content">
              <h3>Выбираем курс</h3>
              <p>
                Изучать программирование интересно, если в ходе обучения писать знакомую игру. Играем и учимся!
              </p>
                    <p>Курс 1: Линейное программирование: пишем игру "Змейка"</p>
                    <p>Курс 2: Ведение в ООП: пишем игру "Тетрис"</p>
                    <p>Курс 3: ООП в действии: пишем игру "Pacman"</p>
            </div>
          </div>
          <div className="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <NavLink to="/courses">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bxl-python" ></i>
                    <h4>Python для начинающих</h4>
                    <p>Python - общепризнанный язык первого обучения и выбран таковым за свою простоту и легкость вхождения</p>
                  </div>
                </div>
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bxl-javascript" ></i>
                    <h4>Javascript для начинающих</h4>
                    <p>Каждый пользуется броузером! А ведь его можно легко запрограммировать! Как это сделать, узнаем на этом курсе </p>
                  </div>
                </div>
                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bxl-c-plus-plus" ></i>
                    <h4>C++ для начинающих</h4>
                    <p>C++ считается более сложным языком для изучения, но нет преград, если учишься играючи!</p>
                  </div>
                </div>
              </div>
            </div>
            </NavLink>
          </div>
        </div>

      </div>
    </section>
  );
}

export default CoursesSection;
