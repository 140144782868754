

function HeroSection() {
  return (
    <section id="hero" className="d-flex justify-content-center align-items-center">
      <div className="container position-relative" data-aos="zoom-in" data-aos-delay="100">
        <h1>Персональные уроки программирования <br/>по скайпу для школьников, студентов и взрослых</h1>
          <h2>Сложно идет изучение программирования? Попробуем изучать вместе! <br/>Достаточно иметь только желание научиться! будет не скучно :)</h2>
            <a href="#courses" className="btn-get-started">Попробовать!</a>
    </div>
  </section>
  );
}

export default HeroSection;
