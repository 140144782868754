import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import IndexPage from './pages/IndexPage';
import CoursesPage from './pages/CoursesPage';
import UnknownPage from './pages/UnknownPage';


import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route exact path="/courses" component={CoursesPage} />
        <Route path="*" component={UnknownPage} />
      </Switch>
    </Router>
  );
}

export default App;
