
// import './App.css';

function Footer() {
  return (
  <footer id="footer">
    <a name="contact"></a>

    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6 footer-contact">
            <h3>Сергей Зобин</h3>
            <p>Решились заниматься? Добавляйте мои контакты в мессенджер, пишите, и мы договоримся, когда провести <b>бесплатное</b> пробное занятие</p>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Контакты</h4>
            <ul>
                <li><i className="bx bxl-skype"></i> <a href="skype:sergey.zobin?chat" target="_blank" rel="noreferrer">Skype: sergey.zobin </a></li>
                <li><i className="bx bxl-telegram"></i> <a href="https://t.me/szobin" target="_blank" rel="noreferrer">Telegram: @szobin </a></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Персональные курсы</h4>
            <ul>                     
              <li><i className="bx bxl-python" ></i><a href="#courses">Python для начинающих</a></li>
              <li><i className="bx bxl-javascript" ></i><a href="#courses">Javascript для начинающих</a></li>
              <li><i className="bx bxl-c-plus-plus" ></i><a href="#courses">C++ для начинающих</a></li>
            </ul>
          </div>

        </div>
      </div>
    </div>

    <div className="container d-md-flex py-4">

      <div className="mr-md-auto text-center text-md-left">
        <div className="copyright">
            &copy; 2021 <strong><span>Sergey Zobin</span></strong>. All Rights Reserved
        </div>
      </div>
      <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="https://www.facebook.com/szobin" className="facebook" target="_blank" rel="noreferrer"><i className="bx bxl-facebook"></i></a>
          <a href="skype:sergey.zobin?chat" className="google-plus" target="_blank" rel="noreferrer"><i className="bx bxl-skype"></i></a>
      </div>
    </div>
  </footer>
  );
}

export default Footer;
