import Header from '../components/Header';
import Footer from '../components/Footer';
import Unknown from '../components/Unknown';


function UnknownPage() {
  return (
    <div className="App">
          <Header />
          <Unknown />
          <Footer />
     </div>
   );
}

export default UnknownPage;
