import { NavLink } from 'react-router-dom';

const mainMenu = [
  { title: "Home", ref: "/" },
  { title: "Курс на выбор", ref: "/courses" },
];


function Header() {
  const aRef = window.location.pathname;
  const actualMenu = mainMenu.map((item, i) => {
    const c = item.ref == aRef ? "active" : "";
    return (<li key={i} className={c}><NavLink to={item.ref}>{item.title}</NavLink></li>);
  });

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">

        <h1 className="logo mr-auto"><NavLink to="/" title="Персональные уроки программирования" >Сергей Зобин</NavLink></h1>

          <nav className="nav-menu d-none d-lg-block">
          <ul className="active">
              {actualMenu}
              <li><a href="#contact" title="Кликните, чтобы посмотреть контакты">Контакты</a></li>
            </ul>
          </nav>

        <a href="#courses" className="get-started-btn" title="Выберите подходящий вам курс">Начать!</a>
    </div>
  </header>
  );
}

export default Header;
