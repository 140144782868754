

function Breadcrumbs() {
  return (
    <div className="breadcrumbs">
      <div className="container">
        <h2>Персональные уроки программирования по скайпу</h2>
        <p>Сложно идет изучение программирования? Давайте изучать вместе! Научимся играючи! </p>
      </div>
    </div>
  );
}

export default Breadcrumbs;
